.coursedetailpage {
    max-width: 1200px;
    margin: 0 auto;  
    padding: 40px 20px;
}

.cdtitle {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.dash {
  height: 2px;
  width: 50px;
  background-color: #007bff;
  margin-bottom: 30px;
}

.coursedetail {
  display: flex;
  align-items: flex-start;
}

.cdimg {
  max-width: 100%;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-right: 30px;
}

.cdesc {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.hordash {
  height: 2px;
  width: 50px;
  background-color: #007bff;
}

.verdash {
  height: 2px;
  width: 100%;
  background-color: #007bff;
  margin-top: 20px;
  margin-bottom: 30px;
}

.button {
  margin-top: 30px;
}

.buttonlink {
  display: inline-block;
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.buttonlink:hover {
  background-color: #0056b3;
}

.cdescription {
  flex: 1;
  margin-left: 50px;
}

.cdescription h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.cdescription p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
}



@media (max-width: 450px) {
  .coursedetail {
    display: inline-block;
    margin: 0;
  }

  .cdimg {
    margin-right: 0;
  }

  .cdescription {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (min-width: 451px) and (max-width: 576px) {
  .coursedetail {
    display: inline-block;
    margin: 0;
  }

  .cdimg {
    margin-right: 0;
  }

  .cdescription {
    margin-left: 0;
    margin-top: 20px;
  }
}
 
@media (min-width: 577px) and (max-width: 768px) {
  .coursedetail {
    display: inline-block;
  }

  .cdescription {
    margin-left: 0;
    margin-top: 30px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .coursedetail {
    display: inline-block;
  }

  .cdescription {
    margin-left: 0;
    margin-top: 50px;
  }
}