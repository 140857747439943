.team-member {
    text-align: center;
    margin: 20px 100px;
    
  }
  
  .team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .team-member h3 {
    margin: 10px 0;
  }
  
  .team-member p {
    color: #777;
  }
  