.navbarcontainer{
  padding-top: 0;
  margin-top: 0;
}

.topnav{
  background-color: #f2f5f7;
  height: 40px;
  align-items: center;
  display: flex;
  gap: 20px;
}

.topnavcontainer {
  display: flex;
  margin-left: 50px;
  gap: 30px;
}

.infocontainer {
  font-family: 'Lato',sans-serif;
  font-weight: 700;
  color: #3a3d41;
  display: flex;
}

.topnavicon {
  margin-top: 18px;
  margin-right: 20px;
  width: 14px;
  height: 14px;
}

.navbar {
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5;
    height: 80px; 
    margin: 0px 50px;
  }

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  margin-top: 5px;
}


.logo-container span {
  font-size: 23px;
  font-family: Arial, Helvetica, sans-serif;
}

.logo-container span:hover{
  color: #1f2a4e;
  transform: scale(1.1);
  transition: transform 0.2s;
}

.logoimg{
  width: 120px;
  padding: 0;
  margin: 0;
}

.menu {
  display: none;
}

.menu-items {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #333;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
  position: fixed;
}

.menu-items.show {
  transform: translateX(0);
}

.menu-item {
  padding: 15px;
  border-bottom: 1px solid white;
  text-align: center;
}

.menu-item Link {
  text-decoration: none;
}

.link-container{
  text-align: center;
  display: flex;
  margin-left: 180px;
  margin-top: 10px;
  justify-content: space-between;
}

.link-div{
  text-align: center;
  margin: 20px;
}

.link-div:hover{
  transform: scale(1.1);
  transition: transform 0.2;
}

.links{
  padding-bottom: 15px;
  font-size: 15px;
  color: #282c35;
  font-family: 'Roboto Slab',serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .5px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  text-decoration: none;
}

.links:hover{
  color: #1f2a4e;
}

.contactus-container{
    display: inline-block;
    margin: 25px;
    padding: 15px;
}

.contactus {
    color: #1f2a4e;
    text-decoration: none;
    font-size: 15px;
    margin: 0;
    border: 1px white solid;
    padding: 13px;
    border-radius: 10px;
    background-color:#1f2a4e;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
}

.contactus-container:hover{
  transform: scale(1.1);
  transition: transform 0.7s;
}

.contactus:hover{
  color: #1f2a4e;
  border: 1px #1f2a4e solid;
  background-color: #fff;
  transition: background-color 0.8s;
}

@media  (max-width: 450px) {
  .topnav{
    display: none;
  }
  
  .navbar{
    margin: 0;
    width: 100%;
    height: 50px;
  }

  .logo-container span {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .logoimg{
    width: 70px;
    padding: 0;
    margin: 0;
  }

  .link-container {
    display: none;
  }
  .menu {
    display: block;
    color: black;
    margin-right: 20px;
    font-size: 30px;
    cursor: pointer;
    z-index: 2;
    margin-top: 10px;
  }
  
  .menu-item {
    margin-top: 10px;
  }

  .menulink {
    color: white;
    text-decoration: none;
  }

  .menulink:hover {
    color: wheat;
  }

  .hamburger,
  .close-button {
    font-size: 30px;
  }

  .contactus-container{
    display: none
  }
}

@media (min-width: 451px) and (max-width: 576px) {
  .navbar{
    margin: 0;
    width: 100%;
    height: 50px;
  }

  .topnav{
    background-color: #f2f5f7;
    height: 40px;
    display: flex;
  }

  .topnavcontainer {
    margin: 0;
    gap: 15px;
    margin-left: 20px;
    margin-top: 8px;
  }

  .topnavcontact {
    font-size: 13px;
  }
  
  .topnavicon {
    margin-top: 15px;
    width: 14px;
    height: 14px;
  }

  .logo-container span {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .logoimg{
    width: 70px;
    padding: 0;
    margin: 0;
  }

  .menu {
    display: block;
    color: black;
    margin-right: 20px;
    font-size: 30px;
    cursor: pointer;
    z-index: 2;
    margin-top: 10px;
  }
  
  .menu-item {
    margin-top: 10px;
  }

  .menulink {
    color: white;
    text-decoration: none;
  }

  .menulink:hover {
    color: wheat;
  }

  .link-container {
    display: none;
  }

  .contactus-container{
    display: none
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .navbar{
    margin: 0;
    width: 100%;
    height: 60px;
  }

  .topnavcontact {
    font-size: 13px;
    margin-top: 15px;
  }

  .logo-container span {
    margin-top: 8px;
    font-size: 23px;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
  }

  .logoimg{
    width: 100px;
    padding: 0;
    margin: 0;
  }

  .link-container {
    display: none;
  }

  .contactus-container {
    display: none;
  }

  .menu {
    display: block;
    color: black;
    margin-right: 20px;
    font-size: 30px;
    cursor: pointer;
    z-index: 2;
    margin-top: 10px;
  }

  .menulink {
    color: white;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .navbar{
    margin: 0;
    width: 100%;
    height: 60px;
  }

  .topnavcontact {
    font-size: 15px;
  }

  .logo-container span {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .logoimg{
    width: 70px;
    padding: 0;
    margin: 0;
  }

  .link-container {
    margin: 0;
  }

  .link-div {
    margin: 0;
    margin-left: 20px;
    margin-top: 10px;
  }

  .links {
    font-size: 13px;
  }

  .contactus-container {
    margin: 0;
    margin-top: 10px;
  }

  .contactus {
    padding: 8px;
    font-size: 13px;
  }
}


@media (min-width: 993px) and (max-width: 1430px) {
  .navbar{
    margin: 0;
    width: 100%;
    height: 60px;
  }

  .topnavcontact {
    font-size: 15px;
  }

  .logo-container span {
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
  }

  .logoimg{
    width: 70px;
    padding: 0;
    margin: 0;
  }

  .link-container {
    margin: 0;
  }

  .link-div {
    margin: 0;
    margin-left: 20px;
    margin-top: 10px;
  }

  .links {
    font-size: 17px;
  }

  .contactus-container {
    margin: 0;
    margin-top: 10px;
  }

  .contactus {
    padding: 8px;
    font-size: 17px;
  }
}