.studentheader {
    background-image: url("../../assets/images/studentheaderbg.jpg");
    height: 600px;
    background-size: cover;
    background-position: center;
    margin-top: 10px;
}

.studentwhy {
    text-align: center;
    margin-top: 70px;
}

.studentwhyh1 {
    color: #2f303a; 
    font-size: 30px;
}

.studentwhyh1 span {
    color: blue; 
}

.studentoppos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .studentoppo {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    text-align: center;
    width: calc(33.33% - 20px); /* Adjust margin and width as needed */
    animation: bounceIn 0.5s; /* Apply the animation here */
    transition: transform 0.2s ease;
  }

  .bounceIn {
    animation: bounceIn 1s;
  }
  
  @keyframes bounceIn {
    from {
        opacity: 0;
        transform: scale(0.3);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
  
.studentoppo:hover {
    transform: translateY(-5px);
}
  
.studentoppoicon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}
  
.studentopponame {
    font-size: 18px;
    margin-bottom: 5px;
}

.studentoppodesc {
    font-size: 17px;
    color: #777;
}

.hrodiv {
    text-align: center;
}

.studentdash {
    border: 1px solid #ebedef;
    margin-top: 70px;
}

.studentimgtxtcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    background-color: #f9f9f9;
}
  
.studentimgtxt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
  
.studentimgtxt-content {
    flex: 1;
    padding-right: 20px;
}
  
.featTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}
  
.featTitle:hover {
    color: #0d7be5;
}

p {
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
}
  
.feathead {
    font-size: 18px;
    font-weight: bold;
    color: #0d7be5;
}
  
.feathead a {
    color: #0d7be5;
    text-decoration: none;
    border-bottom: 1px solid #0d7be5;
    transition: border-bottom-color 0.3s ease;
}
  
.feathead a:hover {
    border-bottom-color: transparent;
}
  
.studentimgtxt-image {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
  
.studentlearnimg {
    max-width: 100%;
    height: auto;
    max-height: 400px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 576px) {
  .studentheader {
    width: 100%;
    height: 200px;
  }

  .studentwhy {
    margin-top: 50px;
  }

  .studentwhyh1 {
    font-size: 20px;
  }

  .studentoppo {

  }

  .studentoppoicon {
    width: 40px;
    height: 40px;
  }

  .studentopponame {
    font-size: 15px;
  }

  .studentoppodesc {
    font-size: 15px;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .studentheader {
    width: 100%;
    height: 250px;
  }

  .studentwhy {
    margin-top: 50px;
  }

  .studentwhyh1 {
    font-size: 22px;
  }

  .studentoppo {

  }

  .studentoppoicon {
    width: 45px;
    height: 45px;
  }

  .studentopponame {
    font-size: 15px;
  }

  .studentoppodesc {
    font-size: 15px;
  }
}


@media (min-width: 769px) and (max-width: 992px) {
  .studentheader {
    width: 100%;
    height: 350px;
  }

  .studentwhy {
    margin-top: 50px;
  }

  .studentwhyh1 {
    font-size: 25px;
  }

  .studentoppoicon {
    width: 55px;
    height: 55px;
  }

  .studentopponame {
    font-size: 17px;
  }

  .studentoppodesc {
    font-size: 16px;
  }
}

