.filteredcoursePage {
  width: 100%;
  min-height: 100vh;
  background-color: #F0F8FF;
}

.filteredcoursepage {
  margin: 10px 120px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.filteredcoursetitle {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}

.filtercard {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  padding-top: 50px;
  gap: 130px; /* Reduced gap for better mobile layout */
}

.filtercoursediv {
  flex: 0 1 calc(25% - 20px); /* Four cards per row on larger screens */
  max-width: calc(25% - 20px);
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  margin-bottom: 20px;
}

.filtercoursediv:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.filtercourseimg {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.courseh2 {
  font-size: 20px;
  margin: 0;
}

.infobutton {
  display: inline-block;
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.infobutton:hover {
  background-color: #0056b3;
}

/* Media query for tablets (768px width) */
@media (max-width: 768px) {
  .filteredcoursepage {
    margin: 10px 30px; /* Reduced margin for better mobile layout */
  }

  .filtercard {
    gap: 20px; /* Adjusted gap for better mobile layout */
  }

  .filtercoursediv {
    flex: 0 1 calc(50% - 20px); /* Two cards per row on tablets */
    max-width: calc(50% - 20px);
  }
}

/* Media query for mobile devices (576px width) */
@media (max-width: 576px) {
  .filteredcoursepage {
    margin: 10px 15px; /* Further reduced margin for smaller screens */
  }

  .filtercard {
    gap: 10px; /* Reduced gap for smaller screens */
    padding: 10px; /* Reduced padding for smaller screens */
  }

  .filtercoursediv {
    flex: 0 1 calc(100% - 20px); /* One card per row on mobile devices */
    max-width: calc(100% - 20px);
  }
}
