
.aboutheader {
    background-image: url("../../assets/images/aboutheaderbg.jpg");
    margin-top: 10px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 400px; 
    display: flex;
}

.aboutheaderdiv {
    display: flex;
}

.aboutheadertitle {
    font-size: 3.5rem;
    color: #1f2a4e;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
    margin-top: 170px;
}

.aboutheaderlogo {
    max-width: 400px; 
    margin-right: 10px;
    margin-left: 150px;
}

.aboutpagedash {
    margin-top: 100px;
    border: 0.8px solid #ebedef;
}

.aboutdescdiv {
    max-width: 1250px;
    margin: auto;
    margin-top: 70px;
}

.aboutdescp {
    letter-spacing: .5px;
    font-size: 18px;
    color: #5f5f5f;
    line-height: 1.5em;
    font-family: 'Lato',sans-serif;
    margin: 20px 150px;
    padding-bottom: 30px;
    text-align: center;
}

.ourvalues {
    padding-top: 50px;
}

.ourvaluestitle {
    color: #2f303a;
    font-family: 'Roboto Slab',serif;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    line-height: 1.3 em;
}


.values {
    display: flex;
    justify-content: center; 
    align-items: center;
}

.value {
    text-align: center;
    opacity: 0;
    animation: bounceIn 1s ease-in-out forwards;
    margin: 30px 120px;
}

@keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, 100% {
        opacity: 0;
        transform: translateY(100px);
    }
    50% {
        opacity: 1;
        transform: translateY(0);
    }
}

.valueicon {
    width: 60px;
    height: 60px;
}

.valuename {
    margin-top: 10px;
}


.ourvaluesdesc {
    padding-right: 20%;
    padding-left: 20%;
    text-align: center;
}

.ourvaluesdescp { 
    letter-spacing: .5px;
    font-size: 20px;
    color: #5f5f5f;
    line-height: 1.5em;
    font-family: 'Lato',sans-serif;
    margin: 0;
    margin-bottom: 0px;
}

.ourvision {
    padding-top: 50px;
}

.ourvisiontitle {
    color: #2f303a;
    font-family: 'Roboto Slab',serif;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    line-height: 1.3 em;
}

.ourvisiondesc {
    padding-right: 20%;
    padding-left: 20%; 
}

.ourvisiondescp {
    padding: 0;
    margin: 0;
    color: #38393b;
    line-height: 1.5em;
    text-align: center;
    font-size: 1.5rem;
    font-family: inherit;
    font-weight: 500;
}

@media (max-width: 576px) {
    .aboutheader {
        width: 100%;
        height: 200px;
    }

    .aboutheaderlogo {
        width: 200px;
        height: 200px;
        margin: 0;
    }

    .aboutheadertitle {
        font-size: 30px;
        margin: 0;
        margin-top: 70px;
    }
    
    .aboutdescdiv { 
        width: 100%;
        margin: auto;
        margin-top: 70px;
    }
    
    .aboutdescp {
        margin: 0px 30px;
        font-size: 17px;
    }

    .ourvalues {
        padding: 30px;
    }

    .ourvaluesdesc {
        padding: 0;
        margin: 0px 20px;
    }

    .ourvaluesdescp {
        font-size: 17px;
    }

    .ourvaluestitle {
        font-size: 25px;
    }

    .value {
        margin: 20px 50px;
    }

    .valueicon {
        width: 40px;
        height: 40px;
    }
    
    .valuename {
        margin-top: 10px;
        font-size: 17px;
    }

    .aboutpagedash {
        margin-top: 40px;
    }

    .ourvisiontitle {
        font-size: 25px;
    }

    .ourvisiondesc {
        margin: 0px 30px;
        padding: 0;
    }

    .ourvisiondescp {
        font-size: 17px;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .aboutheader {
        width: 100%;
        height: 300px;
    }

    .aboutheaderlogo {
        width: 250px;
        height: 250px;
        margin: 0;
    }

    .aboutheadertitle {
        font-size: 35px;
        margin: 0;
        margin-top: 120px;
    }
    
    .aboutdescdiv { 
        width: 100%;
        margin: auto;
        margin-top: 70px;
    }
    
    .aboutdescp {
        margin: 0px 30px;
        font-size: 17px;
    }

    .ourvalues {
        padding: 30px;
    }

    .ourvaluesdesc {
        padding: 0;
        margin: 0px 20px;
    }

    .ourvaluesdescp {
        font-size: 17px;
    }

    .ourvaluestitle {
        font-size: 30px;
    }

    .value {
        margin: 20px 70px;
    }

    .valueicon {
        width: 40px;
        height: 40px;
    }
    
    .valuename {
        margin-top: 10px;
        font-size: 17px;
    }

    .aboutpagedash {
        margin-top: 40px;
    }

    .ourvisiontitle {
        font-size: 30px;
    }

    .ourvisiondesc {
        margin: 0px 30px;
        padding: 0;
    }

    .ourvisiondescp {
        font-size: 18px;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .aboutheader {
        width: 100%;
        height: 300px;
    }

    .aboutheaderlogo {
        width: 300px;
        height: 300px;
        margin: 0;
    }

    .aboutheadertitle {
        font-size: 45px;
        margin: 0;
        margin-top: 130px;
    }
    
    .aboutdescdiv { 
        width: 100%;
        margin: auto;
        margin-top: 70px;
    }
    
    .aboutdescp {
        margin: 0px 30px;
        font-size: 17px;
    }

    .ourvalues {
        padding: 30px;
    }

    .ourvaluesdesc {
        padding: 0;
        margin: 0px 20px;
    }

    .ourvaluesdescp {
        font-size: 17px;
    }

    .ourvaluestitle {
        font-size: 35px;
    }

    .value {
        margin: 20px 100px;
    }

    .valueicon {
        width: 50px;
        height: 50px;
    }
    
    .valuename {
        margin-top: 10px;
        font-size: 20px;
    }

    .aboutpagedash {
        margin-top: 40px;
    }

    .ourvision {
        padding: 0;
    }

    .ourvisiontitle {
        font-size: 35px;
    }

    .ourvisiondesc {
        margin: 0px 30px;
        padding: 0;
    }

    .ourvisiondescp {
        font-size: 20px;
    }
}