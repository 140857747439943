.contactheader {
    position: relative;
    background-image: url("../../assets/images/contactpageheaderbg.jpeg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    margin-top: 10px;
}

.contactheader::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%; /* Apply contrast to the right half */
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    mix-blend-mode: difference; /* Apply blend mode for contrast effect */
    z-index: 1; /* Place the overlay above the image */
}

.contactcontainer {
    position: relative;
    max-width: 1250px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    margin-top: 15px;
}

.leftcolumn {
    flex-basis: 60%; /* 70% width */
    padding: 20px;
    margin-left: 30px;
}

.leftcolumnheader {
    padding: 0;
    margin: 0;
    color: #38393b;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

label {
    font-family: 'Lato',sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: #333;
    margin: 8px 0px;
}

.success-message {
    color: green;
    font-size: 20px;
}

.rightcolumn {
    flex-basis: 40%; /* 30% width */
    padding: 20px;
}

.contactinput {
    min-height: 55px;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 3px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1) !important;
    -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.1) !important;
    padding: 3px 5px;
    color: #333;
    margin-bottom: 30px;
}

.textarea {
    min-height: 100px;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 3px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1) !important;
    -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.1) !important;
    padding: 12px 15px;
    color: #333;
    margin-bottom: 30px;
    width: 97%;
}

.contactinfo {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    margin-top: 50px;
    margin-left: 50px;
}

.contactinfodiv {
    margin-left: 50px;
}

.contactphoneicon {
    max-height: 25px;
}

.phone {
    display: flex;
    align-items: center;
}

.phonetitle {
    font-size: 18px;
    font-family: 'Roboto Slab',sans-serif;
    color: #333;
    padding: 0;
    line-height: normal;
    margin-left: 20px;
}

.phonenumber {
    font-size: 18px;
    font-family: 'Roboto Slab',sans-serif;
    color: #0d7be5;
    padding: 0;
    line-height: normal;
    margin-left: 40px;
    margin-top: 0;
}

.contactsocicon {
    color: #333;
    margin-left: 40px;
    font-size: 20px;
}

@media (max-width: 450px) {
    .contactheader {
        height: 200px;
    }

    .contactcontainer {
        display: inline-block;
    }

    .leftcolumn {
        margin: 0;
    }

    .rightcolumn {
        padding: 0;
        margin: 0;
    }

    .contactinfo {
        margin-left: 0;
    }

    .contactinfodiv {
        margin-left: 25px;
    }

    .textarea { 
        width: 95%;
    }

    .contactinfo {
        margin: 0;
    }
}

@media (min-width: 451px) and (max-width: 576px) {
    .contactheader {
        height: 200px;
    }

    .contactcontainer {
        display: inline-block;
    }

    .leftcolumn {
        margin: 0;
    }

    .rightcolumn {
        padding: 0;
    }

    .textarea {}

    .contactinfo {
        margin: 0;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .contactheader {
        height: 200px;
    }

    .contactcontainer {
        display: inline-block;
    }

    .leftcolumn {
        margin: 0;
        margin-right: 80px;
    }

    .rightcolumn {
        padding: 0;
    }

    .textarea {}

    .contactinfo {
        margin: 0;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .contactheader {
        height: 200px;
    }

    .leftcolumn {
        margin: 0;
    }

    .rightcolumn {
        padding: 0;
        margin-right: 20px;
        margin-top: 100px;
    }

    .textarea {}

    .contactinfo {
        margin: 0;
    }
}

@media (min-width: 993px) and (max-width: 1430px) {
    
    .rightcolumn {
        padding: 0;
        margin-right: 20px;
        margin-top: 100px;
    }

    .contactinfo {
        margin: 0;
    }
}