.team-page {
    text-align: center;
    padding: 40px;
  }
  
  .team-page h1 {
    margin-bottom: 20px;
  }
  
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  