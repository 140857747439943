.tutorheader {
    background-image: url("../../assets/images/tutorpageheaderbg.jpg");
    height: 600px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* This will move the content to the bottom (right side) */
    margin-top: 10px;
}

.tutorheaderdiv {
    align-self: flex-end;
    padding-bottom: 270px;
    padding-right: 100px;
    text-align: center;
}

.tutorheaderh1 {
    color: blue;
    padding-bottom: 30px;
}

.tutorheaderh2 {
    color: #38393b;
    font-size: 20px;
    padding-bottom: 30px;
}

.tutorheaderlink {
    color: white;
    border: none;
    background-color: blue;
    width: 150px;
    padding: 15px 40px;
    border-radius: 30px;
}

.tutorheaderlink:hover {
    text-decoration: none;
}

.tutorwhy {
    text-align: center;
    margin-top: 70px;
}

.tutorwhyh1 {
    font-size: 30px;
    color: #2f303a; 
}

.tutorwhyh1 span {
    color: blue; 
}

.description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.description-content {
    color: gray;
    overflow: hidden;
    transition: max-height 0.3s ease;
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 30px;
    padding: 0px 200px;
    margin-top: 0;
}

.show-more-less {
    display: flex;
    align-items: center;
}

.show-link {
    font-size: 16px;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 10px;
}

.show-link:hover {
    color: darkblue;
}

.kickstart {
    font-size: 25px;
    color: #0d7be5;
    font-style: italic;
    font-family: 'Lato',sans-serif;
    font-weight: 700;
    margin-bottom: 15px;
}

.hro {
    background-color:  #0d7be5;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    line-height: normal;
    background-size: auto 100%;
    position: relative;
    font-size: 18px;
    color: rgba(255,255,255,1) !important;
    padding: 18px 42px;
    border-radius: 100px;
    font-family: 'Lato',sans-serif;
    font-weight: 700;
    background-position: center !important;
    display: inline-block;
    outline: none !important;
    text-decoration: none;
}

.oppos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .oppo {
    padding: 20px;
    margin: 10px;
    border-radius: 5px;
    text-align: center;
    width: calc(33.33% - 20px); /* Adjust margin and width as needed */
    animation: bounceIn 0.5s; /* Apply the animation here */
    transition: transform 0.2s ease;
  }

  .bounceIn {
    animation: bounceIn 1s;
  }
  
  @keyframes bounceIn {
    from {
        opacity: 0;
        transform: scale(0.3);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
  
.oppo:hover {
    transform: translateY(-5px);
}
  
.oppoicon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}
  
.opponame {
    font-size: 18px;
    margin-bottom: 5px;
}

.oppodesc {
    font-size: 17px;
    color: #777;
}

@media (max-width: 450px) {
    .tutorheader {
        width: 100%;
        height: 200px;
    }

    .tutorheaderdiv {
        padding: 0;
        margin-bottom: 50px;
    }

    .tutorheaderh1 {
        padding: 0;
        font-size: 12px;
    }

    .tutorheaderh2 {
        padding: 0;
        font-size: 11px;
    }

    .tutorheaderlink {
        padding: 10px;
        font-size: 12px;
    }

    .tutorwhy {
        margin-top: 50px;
    }

    .tutorwhyh1 {
        font-size: 20px;
    }

    .description-content {
        padding: 0;
        font-size: 15px;
        margin: 0px 20px;
    }

    .oppoicon {
        width: 40px;
        height: 40px;
    }

    .opponame {
        font-size: 15px;
    }

    .oppodesc {
        font-size: 15px;
    }

    .kickstart {
        font-size: 17px;
    }

    .hro {
        font-size: 13px;
        padding: 15px;
    }
}

@media (min-width: 451px) and (max-width: 576px) {
    .tutorheader {
        width: 100%;
        height: 200px;
    }

    .tutorheaderdiv {
        padding: 0;
        margin-bottom: 50px;
    }

    .tutorheaderh1 {
        padding: 0;
        font-size: 15px;
    }

    .tutorheaderh2 {
        padding: 0;
        font-size: 14px;
    }

    .tutorheaderlink {
        padding: 10px;
        font-size: 14px;
    }

    .tutorwhy {
        margin-top: 50px;
    }

    .tutorwhyh1 {
        font-size: 20px;
    }

    .description-content {
        padding: 0;
        font-size: 15px;
        margin: 0px 20px;
    }

    .oppoicon {
        width: 40px;
        height: 40px;
    }

    .opponame {
        font-size: 15px;
    }

    .oppodesc {
        font-size: 15px;
    }

    .kickstart {
        font-size: 17px;
    }

    .hro {
        font-size: 13px;
        padding: 15px;
    }
}

@media (min-width: 577px) and (max-width: 768px) {
    .tutorheader {
        width: 100%;
        height: 250px;
    }

    .tutorheaderdiv {
        padding: 0;
        margin-bottom: 85px;
        margin-right: 20px;
    }

    .tutorheaderh1 {
        padding: 0;
        font-size: 16px;
    }

    .tutorheaderh2 {
        padding: 0;
        font-size: 14px;
    }

    .tutorheaderlink {
        padding: 10px;
        font-size: 14px;
    }

    .tutorwhy {
        margin-top: 50px;
    }

    .tutorwhyh1 {
        font-size: 22px;
    }

    .description-content {
        padding: 0;
        font-size: 16px;
        margin: 0px 20px;
    }

    .oppoicon {
        width: 45px;
        height: 45px;
    }

    .opponame {
        font-size: 15px;
    }

    .oppodesc {
        font-size: 15px;
    }

    .kickstart {
        font-size: 18px;
    }

    .hro {
        font-size: 15px;
        padding: 15px;
    }
}


@media (min-width: 769px) and (max-width: 992px) {
    .tutorheader {
        width: 100%;
        height: 350px;
    }

    .tutorheaderdiv {
        padding: 0;
        margin-bottom: 150px;
        margin-right: 40px;
    }

    .tutorheaderh1 {
        padding: 0;
        font-size: 20px;
    }

    .tutorheaderh2 {
        padding: 0;
        font-size: 18px;
    }

    .tutorheaderlink {
        padding: 10px;
        font-size: 17px;
    }

    .tutorwhy {
        margin-top: 50px;
    }

    .tutorwhyh1 {
        font-size: 25px;
    }

    .description-content {
        padding: 0;
        font-size: 17px;
        margin: 0px 20px;
    }

    .oppoicon {
        width: 55px;
        height: 55px;
    }

    .opponame {
        font-size: 17px;
    }

    .oppodesc {
        font-size: 16px;
    }

    .kickstart {
        font-size: 20px;
    }

    .hro {
        font-size: 15px;
        padding: 15px;
    }
}




