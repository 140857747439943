.applicationpage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f0f0;
  background-color: aliceblue;
}
  
.form {
  width: 400px;
  padding: 50px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
  
.applogo {
  width: 300px;
  margin-bottom: 30px;
}
  
.input {
    margin-bottom: 20px;
}

label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
  
input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
  
button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  text-align: center;
  justify-content: center;
}
  
button:hover {
  background-color: #0056b3;
}

@media (max-width: 576px) {

}

@media (min-width: 577px) and (max-width: 768px) {

}

@media (min-width: 769px) and (max-width: 992px) {

}