.firstpart {
  background-image: url("../../assets/images/first-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 110vh;
  padding: 150px;
  box-sizing: border-box;
  margin-top: 10px;
}

.headtxtdiv {
  padding: 150px;
  text-align: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.4); /* Adjust the opacity as needed */
  border-radius: 20px;
  font-family: 'Roboto Slab',serif;
  font-weight: 700;
  line-height: 1.2em;
}

.h4cca {
  font-size: 40px;
  color: blue;
}

.h2cca {
  font-size: 50px;
  color: red;
  padding-bottom: 10px;
}

.h1cca {
  font-size: 50px;
}

/* YourComponent.css */

.comfort {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 100px auto;
}

.comfortcard {
  padding: 20px;
  transition: transform 0.2s ease;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.comfortcard:hover {
  transform: translateY(-5px);
}

.comforticon {
  width: 80px;
  height: 80px;
  margin: 0 auto 15px;
}

.comfortname {
  font-size: 20px;
  margin: 0;
  color: #333;
}

.comfortdescription {
  font-size: 16px;
  color: #777;
}

.coursesection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  background-color: #f9f9f9;
}

/* Style for the heading */
.coursesheading h1 {
  font-size: 48px;
  margin-bottom: 30px;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for each course */
.second {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1250px; /* Set maximum width to 1250px */
  margin-top: 40px;
}

.course {
  flex: 0 0 calc(25% - 40px);
  text-decoration: none;
  color: #333;
  margin: 20px;
  padding: 0;
  perspective: 1000px;
  transition: transform 0.5s;
}

.card {
  position: relative;
  width: 100%;
  height: 300px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.course:hover .card {
  transform: rotateY(180deg);
}

.card-inner {
  width: 100%;
  height: 100%;
  transform: rotateY(0deg);
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
}

.course:hover .card-inner {
  transform: rotateY(180deg);
}

.courseicon {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 50%;
  background-color: #f9f9f9;
  padding: 25px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.course:hover .courseicon {
  transform: scale(1.1);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.coursetxt {
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  transition: color 0.3s;
}

.course:hover .coursetxt {
  color: #FFD700; /* Change to your desired color */
  transform: translateY(-5px);
}

/* Center the course name */
.course {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.showway {
  margin-top: 100px;
}

.qadamheader {
  text-align: center;
  font-size: 30px;
}

.qadam {
  display: flex;
  margin: 50px 200px;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 200px;
}

.qadamdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qadamtext {
  font-size: 20px;
  margin-bottom: 30px;
}

.qadamicon {
  border: none;
  width: 100px; /* Set the width and height to the same value for a perfect circle */
  height: 100px;
  border-radius: 50%; /* Use 50% for a circular shape */
  background-color: #3498db;
}

.qicon {
  margin-top: 35px;
  font-size: 30px;
  color: white;
}

.qadamway {
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 20px;
}

.abt {
  background-image: url("../../assets/images/about-bg.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  padding: 50px 0;
  box-sizing: border-box;
  margin-top: 100px;
}

.aboutheading{
  text-align: center;
  font-family:Arial, Helvetica, sans-serif;
}

.third {
  margin: 50px 120px;
  display: flex;
  height: 450px;
}

.aboutimgdiv {
  border: 1px black solid;
  border-radius: 30px;
  height: 280px;
  margin-top: 90px;
  position: relative;
  z-index: 2;
  background-color:aliceblue;
}

.aboutimg {
  width: 250px;
  margin: 20px;
}

.abouttxt {
  border: none;
  background-color:rgba(55, 106, 224, 0.32);
  margin-left: -120px;
  z-index: 1;
  text-align: center;
  padding: 170px 150px 150px 150px;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
}

.abtxt {
  text-align: center;
}

.soclinks {
  text-align: center;
  margin-top: 80px;
  font-family: Arial, Helvetica, sans-serif;
}

.fourth {
  margin:0px;
  background-color: #1f2a4e;
  height: 150px;
}

.socicons {
  margin:70px 400px;
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.socicon {
  padding: 50px;
  font-size: 50px;
}

.soclink{
  text-decoration: none;
}

.icon {
  color: white;
}

.icon:hover {
  transform: scale(1.1);
}

@media (max-width: 450px) {
  .firstpart {
    width: 100%;
    padding: 10px;
    min-height: 250px;
  }

  .first {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 200px;
    border: none;
    background-color: none;
    text-align: center;
  }

  .headtxtdiv {
    margin: 0;
    padding: 30px;
    margin-top: 15px;
  }

  .h4cca {
    font-size: 20px;
  }

  .h2cca {
    font-size: 20px;
  }

  .h1cca {
    font-size: 20px;
  }

  .comfort {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    max-width: 1200px;
    margin: 50px 0px;
  }

  .comforticon {
    height: 50px;
    width: 50px;
    margin: 0;
  }

  .comfortname{
    font-size: 14px;
    padding: 10px;
  }

  .coursesection {
    padding: 5px;
    min-height: 80vh;
  }

  .course {
    text-decoration: none;
    color: #333;
    margin: 0;
    padding: 0;
  }

  .coursesheading h1 {
    font-size: 40px;
    padding-top: 10px;
  }

  .second {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0px;
    width: 100%;
  }

  .courseicon {
    width: 80px;
    height: 80px;
    margin: 0;
    margin-top: 10px;
  }

  .coursetxt {
    font-size: 16px;
    margin-top: 20px;
  }

  .showway {
    margin-top: 50px;
  }
  
  .qadamheader {
    text-align: center;
    font-size: 20px;
  }
  
  .qadam {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    margin-top: 50px;
  }
  
  .qadamdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .qadamtext {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .qadamicon {
    border: none;
    width: 50px; /* Set the width and height to the same value for a perfect circle */
    height: 50px;
    border-radius: 50%; /* Use 50% for a circular shape */
    background-color: #3498db;
  }
  
  .qicon {
    margin-top: 23px;
    font-size: 25px;
    color: white;
    margin: 13px;
  }
  
  .qadamwaytxt {
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 10px;
    font-size: 13px;
  }


  .abt {
    background-image: none;
    width: 100%;
    margin-top: 0px;
    min-height: 0px;
  }
  
  .aboutheading{
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
  }
  
  .third {
    margin: 0px;
    display: inline-block;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .aboutimgdiv {
    display: none;
  }
  
  .abouttxt {
    border: none;
    background-color:rgba(55, 106, 224, 0.32);
    margin-left: 0px;
    z-index: 1;
    text-align: center;
    padding: 20px;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .abtxt {
    text-align: center;
    font-size: 15px;
  }

  .buttontxt {
    margin: 0;
    margin-top: 15px;
  }

  .soclinks {
    text-align: center;
    margin-top: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
  }
  
  .fourth {
    margin:0px;
    background-color: #1f2a4e;
    height: 110px;
  }
  
  .socicons {
    margin:0px;
    gap: 0px;
  }
  
  .socicon {
    padding: 30px 50px;
    font-size: 50px;
    width: 30px;
    height: 30px;
  }
  
  .soclink{
    text-decoration: none;
  }
  
  .icon {
    color: white;
  }
}

@media (min-width: 450px) and (max-width: 576px) {
  .firstpart {
    width: 100%;
    padding: 10px;
    min-height: 250px;
  }

  .first {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 200px;
    border: none;
    background-color: none;
    text-align: center;
  }

  .headtxtdiv {
    margin: 0;
    padding: 30px;
    margin-top: 15px;
  }

  .h4cca {
    font-size: 20px;
  }

  .h2cca {
    font-size: 20px;
  }

  .h1cca {
    font-size: 20px;
  }

  .comfort {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    max-width: 1200px;
    margin: 50px 0px;
  }

  .comforticon {
    height: 50px;
    width: 50px;
    margin: 0;
  }

  .comfortname{
    font-size: 14px;
    padding: 10px;
  }

  .coursesection {
    padding: 5px;
    min-height: 100vh;
  }

  .course {
    text-decoration: none;
    color: #333;
    margin: 0;
    padding: 0;
  }

  .coursesheading h1 {
    font-size: 40px;
    padding-top: 10px;
  }

  .second {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0px;
    width: 100%;
  }

  .courseicon {
    width: 80px;
    height: 80px;
    margin: 0;
    margin-top: 10px;
  }

  .coursetxt {
    font-size: 16px;
    margin-top: 20px;
  }

  .showway {
    margin-top: 50px;
  }
  
  .qadamheader {
    text-align: center;
    font-size: 20px;
  }
  
  .qadam {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    margin-top: 50px;
  }
  
  .qadamdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .qadamtext {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .qadamicon {
    border: 1px none solid;
    width: 50px; /* Set the width and height to the same value for a perfect circle */
    height: 50px;
    border-radius: 50%; /* Use 50% for a circular shape */
    background-color: #3498db;
  }
  
  .qicon {
    margin-top: 23px;
    font-size: 25px;
    color: white;
    margin: 13px;
  }
  
  .qadamwaytxt {
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 10px;
    font-size: 13px;
  }


  .abt {
    background-image: none;
    width: 100%;
    margin-top: 0px;
    min-height: 0px;
  }
  
  .aboutheading{
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
  }
  
  .third {
    margin: 0px;
    display: inline-block;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .aboutimgdiv {
    display: none;
  }
  
  .abouttxt {
    border: 1px none solid;
    background-color:rgba(55, 106, 224, 0.32);
    margin-left: 0px;
    z-index: 1;
    text-align: center;
    padding: 20px;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .abtxt {
    text-align: center;
    font-size: 15px;
  }

  .buttontxt {
    margin: 0;
    margin-top: 15px;
  }

  .soclinks {
    text-align: center;
    margin-top: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
  }
  
  .fourth {
    margin:0px;
    background-color: #1f2a4e;
    height: 110px;
  }
  
  .socicons {
    margin:0px;
    gap: 0px;
  }
  
  .socicon {
    padding: 30px 50px;
    font-size: 50px;
    width: 30px;
    height: 30px;
  }
  
  .soclink{
    text-decoration: none;
  }
  
  .icon {
    color: white;
  }
}

/* For screens between 577px and 768px */
@media (min-width: 577px) and (max-width: 768px) {
  .firstpart {
    width: 100%;
    padding: 10px;
    min-height: 300px;
  }

  .first {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 200px;
    border: none;
    background-color: none;
    text-align: center;
  }

  .headtxtdiv {
    margin: 0;
    margin-top: 40px;
    padding: 30px;
  }

  .h4cca {
    font-size: 23px;
  }

  .h2cca {
    font-size: 23px;
  }

  .h1cca {
    font-size: 23px;
  }

  .comfort {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    max-width: 1200px;
    margin: 50px 0px;
  }

  .comforticon {
    height: 80px;
    width: 80px;
    margin: 0;
  }

  .comfortname{
    font-size: 17px;
    padding: 10px;
  }

  .coursesection {
    padding: 5px;
    min-height: 100vh;
  }

  .course {
    text-decoration: none;
    color: #333;
    margin: 0;
    padding: 0;
  }

  .coursesheading h1 {
    font-size: 40px;
    padding-top: 10px;
  }

  .second {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0px;
    width: 100%;
  }

  .courseicon {
    width: 80px;
    height: 80px;
    margin: 0;
    margin-top: 10px;
  }

  .coursetxt {
    font-size: 16px;
    margin-top: 20px;
  }

  .showway {
    margin-top: 50px;
  }
  
  .qadamheader {
    text-align: center;
    font-size: 20px;
  }
  
  .qadam {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 30px;
  }
  
  .qadamdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .qadamtext {
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  
  .qadamicon {
    border: 1px none solid;
    width: 70px; /* Set the width and height to the same value for a perfect circle */
    height: 70px;
    border-radius: 50%; /* Use 50% for a circular shape */
    background-color: #3498db;
  }
  
  .qicon {
    margin-top: 23px;
    font-size: 25px;
    color: white;
  }
  
  .qadamway {
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 20px;
  }

  .abt {
    background-image: none;
    width: 100%;
    margin-top: 0px;
    min-height: 0px;
  }
  
  .aboutheading{
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
  }
  
  .third {
    margin: 0px;
    display: inline-block;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .aboutimgdiv {
    display: none;
  }
  
  .abouttxt {
    border: 1px none solid;
    background-color:rgba(55, 106, 224, 0.32);
    margin-left: 0px;
    z-index: 1;
    text-align: center;
    padding: 20px;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .abtxt {
    text-align: center;
  }

  .soclinks {
    text-align: center;
    margin-top: 0px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
  }
  
  .fourth {
    margin:0px;
    background-color: #1f2a4e;
    height: 110px;
  }
  
  .socicons {
    margin:0px 50px;
    gap: 0px;
  }
  
  .socicon {
    padding: 30px;
    font-size: 50px;
  }
  
  .soclink{
    text-decoration: none;
  }
  
  .icon {
    color: white;
  }
}

/* For screens between 769px and 992px */
@media (min-width: 769px) and (max-width: 992px) {
  .firstpart {
    width: 100%;
    padding: 10px;
    min-height: 500px;
  }

  .first {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 200px;
    border: none;
    background-color: none;
    text-align: center;
  }

  .headtxtdiv {
    margin: 0;
    margin-top: 60px;
    padding: 100px;
  }

  .h4cca {
    font-size: 30px;
  }

  .h2cca {
    font-size: 30px;
  }

  .h1cca {
    font-size: 30px;
  }

  .comfort {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px;
    max-width: 1200px;
    margin: 50px 0px;
  }

  .comforticon {
    height: 80px;
    width: 80px;
    margin: 0;
  }

  .comfortname{
    font-size: 17px;
    padding: 10px;
  }

  .coursesection {
    padding: 5px;
    min-height: 70vh;
  }

  .course {
    text-decoration: none;
    color: #333;
    margin: 0;
    padding: 0;
  }

  .coursesheading h1 {
    font-size: 40px;
  }

  .second {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0px;
    width: 100%;
  }

  .courseicon {
    width: 80px;
    height: 80px;
    margin: 0;
    margin-top: 20px;
  }

  .coursetxt {
    font-size: 16px;
    margin-top: 20px;
  }

  .showway {
    margin-top: 50px;
  }
  
  .qadamheader {
    text-align: center;
    font-size: 20px;
  }
  
  .qadam {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 70px;
  }
  
  .qadamdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .qadamtext {
    font-size: 20px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  
  .qadamicon {
    border: 1px none solid;
    width: 70px; /* Set the width and height to the same value for a perfect circle */
    height: 70px;
    border-radius: 50%; /* Use 50% for a circular shape */
    background-color: #3498db;
  }
  
  .qicon {
    margin-top: 23px;
    font-size: 25px;
    color: white;
  }
  
  .qadamway {
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 20px;
    font-size: 17px;
  }

  .abt {
    background-image: none;
    width: 100%;
    margin-top: 0px;
    min-height: 0px;
  }
  
  .aboutheading{
    text-align: center;
    font-family:Arial, Helvetica, sans-serif;
  }
  
  .third {
    margin: 0px;
    display: inline-block;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .aboutimgdiv {
    display: none;
  }
  
  .abouttxt {
    border: 1px none solid;
    background-color:rgba(55, 106, 224, 0.32);
    margin-left: 0px;
    line-height: 30px;
    text-align: center;
    padding: 40px;
    display: inline-block;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .abtxt {
    text-align: center;
    font-size: 18px;
  }

  .soclinks {
    text-align: center;
    margin-top: 70px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
  }
  
  .fourth {
    margin:0px;
    margin-top: 30px;
    background-color: #1f2a4e;
    height: 110px;
  }
  
  .socicons {
    margin:0px 80px;
    gap: 0px;
  }
  
  .socicon {
    padding: 30px;
    font-size: 50px;
  }
  
  .soclink{
    text-decoration: none;
  }
  
  .icon {
    color: white;
  }
}

@media (min-width: 993px) and (max-width: 1430px) {
  .firstpart {
    width: 100%;
    padding: 10px;
    min-height: 500px;
  }

  .first {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: 100%;
    height: 200px;
    border: none;
    background-color: none;
    text-align: center;
  }

  .headtxtdiv {
    margin: 0;
    margin-top: 60px;
    padding: 100px;
  }

  .h4cca {
    font-size: 35px;
  }

  .h2cca {
    font-size: 35px;
  }

  .h1cca {
    font-size: 35px;
  }

  .abt {
  background-image: url("../../assets/images/about-bg.jpg");
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 70vh;
  padding: 50px 0;
  box-sizing: border-box;
  margin-top: 100px;
}

.aboutheading{
  text-align: center;
  font-family:Arial, Helvetica, sans-serif;
}

.third {
  margin: 30px 100px;
  display: flex;
  height: 400px;
}

.aboutimgdiv {
  border: 1px black solid;
  border-radius: 30px;
  height: 280px;
  margin-top: 60px;
  position: relative;
  z-index: 2;
  background-color:aliceblue;
}

.aboutimg {
  width: 250px;
  margin: 20px;
}

.abouttxt {
  border: 1px none solid;
  background-color:rgba(55, 106, 224, 0.32);
  margin-left: -120px;
  z-index: 1;
  text-align: center;
  padding: 150px 150px 150px 150px;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
}

.abtxt {
  text-align: center;
}

.soclinks {
  text-align: center;
  margin-top: 70px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.fourth {
  margin:0px;
  margin-top: 30px;
  background-color: #1f2a4e;
  height: 110px;
}

.socicons {
  margin:0px 80px;
  gap: 0px;
}

.socicon {
  padding: 30px;
  font-size: 50px;
}

.soclink{
  text-decoration: none;
}

.icon {
  color: white;
}
}